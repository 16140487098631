<template>
  <div class="dialogEffect">
    <el-dialog
    :title="$t('text.confirmation')"
    :visible.sync="dialogVisible"
    width="500px"
    :before-close="handleClose">
      <el-form ref="dialogForm" :rules="rules" :model="createData" label-width="100px" label-position="left">
        <el-col v-for="(item, index) in data" :key="index" :span="24">
          <p v-if="item.tip">{{item.tip}}</p>
          <el-form-item :label="item.name" :prop="item.key" v-if="item.type !== 'hidden'">
            <el-input type="textarea" v-if="item.type === 'text_field'" :placeholder="item.hint" v-model="createData[item.key]"></el-input>
            <el-input v-if="item.type === 'text'" :placeholder="item.hint" v-model="createData[item.key]"></el-input>
            <el-select clearable  style="width:100%" :placeholder="item.hint" v-if="item.type === 'select'" v-model="createData[item.key]">
              <el-option v-for="option in item.options" :label="option.name" :value="option.value" :key="option.value"></el-option>
            </el-select>
            <el-date-picker value-format="yyyy-MM-dd" :picker-options="pickerOptions" :disabledDate="disabledDate" style="width:100%" type="date" v-if="item.type === 'date'" :placeholder="item.hint" v-model="createData[item.key]"></el-date-picker>
            <el-date-picker
            v-if="item.type === 'monthrange' || item.type === 'daterange'"
            v-model="createData[item.key]"
            :type="item.type"
            value-format="yyyy-MM-dd"
            range-separator="To"
            :start-placeholder="item.hintStart"
            :end-placeholder="item.hintEnd">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">{{$t('button.cancel')}}</el-button>
        <el-button type="primary" @click="handleClick">{{$t('button.confirm')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Utils from '@/utils/Utils'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default () {
        return false
      }
    },
    data: {
      type: Array,
      defalut () {
        return []
      }
    },
    dateArray: {
      type: Array,
      defalut () {
        return []
      }
    },
    todayVisible: {
      type: Boolean,
      default () {
        return false
      }
    },
    opereteDate: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    const $this = this
    return {
      pickerOptions: {
        disabledDate (time) {
          if ($this.opereteDate) {
            let visible = true
            $this.dateArray.some(item => {
              if (item.date === Utils.getTime(new Date(time.getTime()))) {
                visible = false
                return true
              }
            })
            return visible
          } else if ($this.todayVisible) {
            if (Utils.compareTime(Utils.getTime(new Date()), Utils.getTime(new Date(time.getTime())))) {
              return false
            } else {
              return true
            }
          } else {
            return true
          }
        }
      },
      rules: {},
      createData: {}
    }
  },
  created () {
    this.bindData()
  },
  methods: {
    handleClose () {
      this.$emit('update:dialogVisible', false)
    },
    bindData () {
      if (this.data.length > 0) {
        this.data.forEach(item => {
          this.$set(this.createData, item.key, !Utils.isNull(item.value) ? item.value : '')
          this.$set(this.rules, item.key, [{ required: !(!Utils.isNull(item.optional) && item.optional === true), message: this.$t('text.require', { name: item.name }), trigger: ['blur', 'change'] }])
        })
      }
    },
    handleClick () {
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          this.handleClose()
          this.$emit('doNextConfirm', this.createData)
        } else {
          // console.log('error submit!!')
          return false
        }
      })
    },
    disabledDate () {
      return false
    }
  }
}
</script>

<style lang="scss">
.dialogEffect {
  .el-form-item__label {
    word-break: break-word;
  }
  // .el-form-item--small .el-form-item__label {
  //   line-height: 24px;
  // }
  .el-dialog__header {
    padding: 10px;
    border-bottom: 1px solid #DCDFE6;
    .el-dialog__title {
      font-size: 16px;
      line-height: 16px;
    }
    .el-dialog__headerbtn {
      top: 10px;
      right: 10px;
    }
  }
  .el-dialog__body {
    min-height: 200px;
    padding: 10px 20px;
  }
  .el-dialog__footer {
    text-align: right;
    padding: 10px 20px;
  }
}
</style>
