import request from '@/utils/request'

export const getTicketsList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/ticket_packages',
    params
  })
}

export const getSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/ticket_packages/setup'
  })
}

export const getSiteCollection = () => {
  return request({
    method: 'GET',
    url: '/cms/ticket_packages/collections'
  })
}

export const deleteZooTickets = (id, data) => {
  return request({
    method: 'DELETE',
    url: `/cms/ticket_packages/${id}`,
    data
  })
}

export const postZooTicketsFormData = (data) => {
  return request({
    method: 'POST',
    url: '/cms/ticket_packages',
    data
  })
}

export const putZooTicketsFormData = (id, data) => {
  return request({
    method: 'PUT',
    url: `/cms/ticket_packages/${id}`,
    data
  })
}

export const getTicketPreview = (id, data) => {
  return request({
    method: 'GET',
    url: `/cms/ticket_packages/${id}/preview`,
    data
  })
}

export const getTicketDetail = (id, data) => {
  return request({
    method: 'GET',
    url: `/cms/ticket_packages/${id}`,
    data
  })
}

export const toogleOnlineTicketPackages = (id, type) => {
  return request({
    method: 'POST',
    url: `/cms/ticket_packages/${id}/${type}`
  })
}

export const getPackageTypeSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/ticket_types/setup'
  })
}
export const getTicketsTypeList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/ticket_types',
    params
  })
}

export const deleteZooTicketsType = (id, data) => {
  return request({
    method: 'DELETE',
    url: `/cms/ticket_types/${id}`,
    data
  })
}

export const postZooTicketsTypeFormData = (data) => {
  return request({
    method: 'POST',
    url: '/cms/ticket_types',
    data
  })
}

export const putZooTicketsTypeFormData = (id, data) => {
  return request({
    method: 'PUT',
    url: `/cms/ticket_types/${id}`,
    data
  })
}

export const getTicketTypePreview = (id, data) => {
  return request({
    method: 'GET',
    url: `/cms/ticket_types/${id}/preview`,
    data
  })
}

export const getTicketTypeDetail = (id, data) => {
  return request({
    method: 'GET',
    url: `/cms/ticket_types/${id}`,
    data
  })
}

export const getTicketTypesCollection = (params) => {
  return request({
    method: 'GET',
    url: '/cms/ticket_types/collections',
    params
  })
}

export const getGlobaltixTicket = () => {
  return request({
    method: 'GET',
    url: '/cms/globaltix_tickets'
  })
}

export const toogleOnlineZooTicketsType = (id, type) => {
  return request({
    method: 'POST',
    url: `/cms/ticket_types/${id}/${type}`
  })
}

export const globalTixChange = (id) => {
  return request({
    method: 'GET',
    url: `/cms/globaltix_tickets/${id}`
  })
}

export const globalTixPackageChange = (id, params) => {
  return request({
    method: 'GET',
    url: `/cms/globaltix_packages/${id}`,
    params
  })
}

export const getTicketPackagesCollection = (params) => {
  return request({
    method: 'GET',
    url: '/cms/packages/collections',
    params
  })
}

export const getPackagesSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/packages/setup'
  })
}
export const getPackagesList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/packages',
    params
  })
}

export const deletePackages = (id, data) => {
  return request({
    method: 'DELETE',
    url: `/cms/packages/${id}`,
    data
  })
}

export const postPackagesFormData = (data) => {
  return request({
    method: 'POST',
    url: '/cms/packages',
    data
  })
}

export const putPackagesFormData = (id, data) => {
  return request({
    method: 'PUT',
    url: `/cms/packages/${id}`,
    data
  })
}

export const getPackagesPreview = (id, data) => {
  return request({
    method: 'GET',
    url: `/cms/packages/${id}/preview`,
    data
  })
}

export const getPackagesDetail = (id, data) => {
  return request({
    method: 'GET',
    url: `/cms/packages/${id}`,
    data
  })
}

export const getPackagesCollection = () => {
  return request({
    method: 'GET',
    url: '/cms/packages/collections'
  })
}

export const getGlobaltixPackages = () => {
  return request({
    method: 'GET',
    url: '/cms/globaltix_packages'
  })
}

export const toogleOnlinePackages = (id, type) => {
  return request({
    method: 'POST',
    url: `/cms/packages/${id}/${type}`
  })
}

export const getMultiParkTicketsSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/multi_park_tickets/setup'
  })
}
export const getMultiParkTicketsList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/multi_park_tickets',
    params
  })
}

export const deleteMultiParkTickets = (id, data) => {
  return request({
    method: 'DELETE',
    url: `/cms/multi_park_tickets/${id}`,
    data
  })
}

export const postMultiParkTicketsFormData = (data) => {
  return request({
    method: 'POST',
    url: '/cms/multi_park_tickets',
    data
  })
}

export const putMultiParkTicketsFormData = (id, data) => {
  return request({
    method: 'PUT',
    url: `/cms/multi_park_tickets/${id}`,
    data
  })
}

export const getMultiParkTicketsPreview = (id, data) => {
  return request({
    method: 'GET',
    url: `/cms/multi_park_tickets/${id}/preview`,
    data
  })
}

export const getMultiParkTicketsDetail = (id, data) => {
  return request({
    method: 'GET',
    url: `/cms/multi_park_tickets/${id}`,
    data
  })
}
export const getMultiParkTicketsCollection = () => {
  return request({
    method: 'GET',
    url: '/cms/multi_park_tickets/collections'
  })
}

export const toogleOnlineMultiParkTickets = (id, type) => {
  return request({
    method: 'POST',
    url: `/cms/multi_park_tickets/${id}/${type}`
  })
}

export const getPackageSites = (id) => {
  return request({
    method: 'GET',
    url: `cms/ticket_packages/${id}/related_sites`
  })
}

export const getTicketSync = () => {
  return request({
    method: 'POST',
    url: 'cms/ticket_packages/sync_plus'
  })
}
