export default {
  basicViewItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    span: 8
  }, {
    prop: 'name',
    label: '',
    Object: 'value',
    span: 8
  }, {
    prop: 'name_en',
    label: '',
    Object: 'value',
    span: 8
  }, {
    prop: 'active',
    label: '',
    Object: 'value',
    span: 8,
    back: true
  }, {
    prop: 'ticket_package',
    label: '',
    Object: 'value',
    span: 8
  }, {
    prop: 'sites',
    label: '',
    Object: 'value',
    span: 8
  }, {
    prop: 'peak_season',
    label: '',
    Object: 'value',
    span: 8,
    back: true
  }, {
    prop: 'capacity_control',
    label: '',
    Object: 'value',
    span: 8,
    back: true
  }, {
    prop: 'max_pax',
    label: '',
    Object: 'value',
    span: 8
  }, {
    prop: 'min_pax',
    label: '',
    Object: 'value',
    span: 8
  }, {
    prop: 'listing_price',
    label: '',
    Object: 'value',
    span: 8
  }, {
    prop: 'original_price',
    label: '',
    Object: 'value',
    span: 8
  }, {
    prop: 'ranking',
    label: '',
    Object: 'value',
    span: 8
  }, {
    prop: 'start_date',
    label: '',
    Object: 'value',
    span: 8
  }, {
    prop: 'end_date',
    label: '',
    Object: 'value',
    span: 8
  }, {
    prop: 'days_of_week',
    label: '',
    Object: 'value',
    span: 8
  }, {
    prop: 'age_label',
    label: '',
    Object: 'value',
    span: 8
  }, {
    prop: 'cancellable',
    label: '',
    Object: 'value',
    span: 8,
    back: true
  }, {
    prop: 'local_residence_only',
    label: '',
    Object: 'value',
    span: 8,
    back: true
  }, {
    prop: 'visit_date_required',
    label: '',
    Object: 'value',
    span: 8,
    back: true
  }, {
    prop: 'validity_in_days',
    label: '',
    Object: 'value',
    span: 8
  }, {
    prop: 'paper_ticket_redemption_required',
    label: '',
    Object: 'value',
    span: 8,
    back: true
  }, {
    prop: 'advance_booking_in_minutes',
    label: '',
    Object: 'value',
    span: 8
  }, {
    prop: 'third_party_platform',
    label: '',
    Object: 'value',
    span: 8
  }, {
    prop: 'third_party_product',
    label: '',
    Object: 'value',
    span: 8
  }],
  guide_and_policies: [{
    prop: 'description',
    label: '',
    Object: 'value',
    span: 24
  }, {
    prop: 'booking_time_desc',
    label: '',
    Object: 'value',
    span: 24
  }, {
    prop: 'validity_desc',
    label: '',
    Object: 'value',
    span: 24
  }, {
    prop: 'visitor_applicability',
    label: '',
    Object: 'value',
    span: 24
  }, {
    prop: 'inclusion_desc',
    label: '',
    Object: 'value',
    span: 24
  }, {
    prop: 'redemption_guide',
    label: '',
    Object: 'value',
    span: 24
  }, {
    prop: 'redemption_address',
    label: '',
    Object: 'value',
    span: 24
  }, {
    prop: 'cancellation_guide',
    label: '',
    Object: 'value',
    span: 24
  }, {
    prop: 'additional_guide',
    label: '',
    Object: 'value',
    span: 24
  }],
  third_party_information: [{
    prop: 'last_synced_at',
    label: '',
    Object: 'value',
    span: 8
  }, {
    prop: 'settlement_price',
    label: '',
    Object: 'value',
    span: 8
  }, {
    prop: 't_original_price',
    label: '',
    Object: 'value',
    span: 8
  }, {
    prop: 't_start_date',
    label: '',
    Object: 'value',
    span: 8
  }, {
    prop: 't_end_date',
    label: '',
    Object: 'value',
    span: 8
  }, {
    prop: 't_max_pax',
    label: '',
    Object: 'value',
    span: 8
  }, {
    prop: 't_min_pax',
    label: '',
    Object: 'value',
    span: 8
  }, {
    prop: 'description',
    label: '',
    Object: 'value',
    span: 24
  }, {
    prop: 'inclusions',
    label: '',
    Object: 'value',
    span: 24
  }, {
    prop: 'use_guide',
    label: '',
    Object: 'value',
    span: 24
  }, {
    prop: 'terms_and_conditions',
    label: '',
    Object: 'value',
    span: 24
  }, {
    prop: 'cancellation_policies',
    label: '',
    Object: 'value',
    span: 24
  }]
}
