/**
 * 资源相关请求模块
 */

import request from '@/utils/request'

export const getShopData = (params) => {
  return request({
    method: 'GET',
    url: '/shop/query',
    params
  })
}

// 获取历史记录
export const getHistory = (params) => {
  return request({
    method: 'GET',
    url: '/cms/versions',
    params
  })
}

// 获取过滤选项
export const getFilterList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/filters',
    params
  })
}

// 获取过滤选项
export const getHistoryList = (params) => {
  return request({
    method: 'GET',
    url: 'cms/versions',
    params
  })
}
